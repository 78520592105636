import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="floating-footer">
      <ul>
        <li><a href="#page-1" className="nav-item">ראשי</a></li>
        <li><a href="#page-2" className="nav-item">הצוות</a></li>
        <li><a href="#page-3" className="nav-item">הכוכבים</a></li>
        <li><a href="#page-4" className="nav-item">VOD</a></li>
        <li><a href="#page-5" className="nav-item">צור קשר</a></li>
      </ul>
    </footer>
  );
}

export default Footer;
