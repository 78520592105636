import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import './styles/styles.css';

const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const OurTeam = React.lazy(() => import("./pages/OurTeam"));
const OurStars = React.lazy(() => import("./pages/OurStars"));
const OurVideos = React.lazy(() => import("./pages/OurVideos"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const ShabbatPage = React.lazy(() => import("./pages/ShabbatPage"));

function App() {
  const [loading, setLoading] = useState(true);
  const [isShabbat, setIsShabbat] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    async function fetchShabbatTimes() {
      const location = { lat: 31.7683, lon: 35.2137 }; // ירושלים לדוגמה
      const today = new Date().toISOString().split("T")[0];
      const url = `https://www.hebcal.com/shabbat?cfg=json&geo=pos&latitude=${location.lat}&longitude=${location.lon}&m=50&date=${today}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        const candleLighting = new Date(data.items.find(item => item.category === "candles").date);
        const havdalah = new Date(data.items.find(item => item.category === "havdalah").date);

        const now = new Date();
        setIsShabbat(now >= candleLighting && now <= havdalah);
      } catch (error) {
        console.error("Error fetching Shabbat times:", error);
      }
    }

    fetchShabbatTimes();
    const interval = setInterval(fetchShabbatTimes, 60000); // בדיקה כל דקה
    return () => clearInterval(interval);
  }, []);

  if (isShabbat) {
    return (
      <Suspense fallback={<div className="loading-animation">טוען...</div>}>
        <ShabbatPage />
      </Suspense>
    );
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <div className="content">
          <Suspense fallback={<div className="loading-animation">טוען...</div>}>
            <AboutUs />
            <OurTeam />
            <OurStars />
            <OurVideos />
            <ContactUs />
          </Suspense>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
