import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../images/logo.png"; // Correct path to your logo image
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa'; // Importing react-icons
import { IoLogoYoutube } from 'react-icons/io'; // Importing YouTube icon

import "../styles/styles.css";
import "../styles/mobile.css";

function Header() {
  return (
    <header>
      <div className="header-content">
        <div className="register-btn">
          <Link to="https://app.ezacademy.co.il/login">התחברות</Link>
          {/*<Link to="http://localhost:3001/login">איזור אישי >> </Link>*/}
        </div>

        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>

        <div className="social-icons">
          <a href="https://m.facebook.com/profile.php?id=100092875719936" target="_blank" rel="noopener noreferrer">
            <FaFacebookF /> {/* Facebook icon */}
          </a>
          <a href="https://www.instagram.com/e.z__academy?igsh=bGpzdGNsemx4dGkw" target="_blank" rel="noopener noreferrer">
            <FaInstagram  /> {/* Instagram icon */}
          </a>
          <a href="https://www.tiktok.com/@e.z_football_academy?_t=8qv4jigBqkB&_r=1" target="_blank" rel="noopener noreferrer">
            <FaTiktok/> {/* TikTok icon */}
          </a>

          <a href="https://www.youtube.com/channel/UC-3TrwZG69EyzNSQn-7MnSQ" target="_blank" rel="noopener noreferrer">
            <IoLogoYoutube /> {/* YouTube icon */}
          </a>

        </div>
      </div>
    </header>
  );
}

export default Header;
